// App.js - WEB
import React, { Component, Suspense } from 'react';
import { View } from 'react-native';
import { withRouter } from "react-router-dom";
// import firebase from 'firebase';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../blocks/utilities/src/theme';
import { Skeleton } from '@material-ui/lab';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import WebsiteBuilderTheme from "../../blocks/dashboard/src/WebsiteBuilderTheme.web"
import Appheader from '../../blocks/header/src/AppheaderWrapper.web';
import Appfooter from '../../blocks/footer/src/AppfooterWrapper.web';

const LazyComponent = (importFunc) => {
  return React.lazy(importFunc);
};

const routeMap = {
  Admin: {
    component: require('../../blocks/ss-cms-common-router/src/WebRoutes').default,
    path: '/admin/*',
    exact: true
  },
  OrderManagement: {
    component: LazyComponent(() => import('../../blocks/ordermanagement/src/OrderManagement')),
    path: '/OrderManagement'
  },
  Itemavailability: {
    component: LazyComponent(() => import('../../blocks/itemavailability/src/ItemAvailability')),
    path: '/Itemavailability'
  },
  CountryCodeSelector: {
    component: LazyComponent(() => import('../../blocks/country-code-selector/src/CountryCodeSelector')),
    path: '/CountryCodeSelector'
  },
  ServicesSearch: {
    component: LazyComponent(() => import('../../blocks/catalogue/src/ServicesNew.web')),
    path: '/Services/:searchQuery',
    exact: true
  },
  Services: {
    component: LazyComponent(() => import('../../blocks/catalogue/src/ServicesNew.web')),
    path: '/Services',
    exact: true
  },
  Service: {
    component:  LazyComponent(() => import('../../blocks/catalogue/src/ServiceWrapper')),
    path: '/Service/:slug/:id',
    exact: true
  },
  AddContact: {
    component: LazyComponent(() => import('../../blocks/contactus/src/AddContact')),
    path: '/AddContact'
  },
  EmailAccountLoginBlock: {
    component: LazyComponent(() => import('../../blocks/email-account-login/src/EmailAccountLoginBlock')),
    path: '/EmailAccountLoginBlock'
  },
  StripePayments: {
    component: LazyComponent(() => import('../../blocks/stripepayments/src/StripePayments.web')),
    path: '/StripePayments'
  },
  Categoriessubcategories: {
    component: LazyComponent(() => import('../../blocks/categoriessubcategories/src/Categoriessubcategories')),
    path: '/Categoriessubcategories'
  },

  Home: {
    component: LazyComponent(() => import('../../blocks/dashboard/src/DashboardWrapper')),
    path: '/',
    exact: true
  },
  AlertWeb: {
    component: LazyComponent(() => import('../../blocks/alert/src/AlertBlock.web')),
    path: '*/AlertWeb',
    modal: true
  },
  ContactSuccess: {
    component: LazyComponent(() => import('../../blocks/contactus/src/ContactSuccess')),
    path: '/ContactSuccess'
  },
  Gallery: {
    component: LazyComponent(() => import('../../blocks/photolibrary/src/Gallery.web')),
    path: '/Gallery'
  },
  Appointments: {
    component: LazyComponent(() => import('../../blocks/appointmentmanagement/src/Appointments.web')),
    path: "/Appointments"
  },
  
  AppointmentReschedule: {
    component: LazyComponent(() => import('../../blocks/appointmentmanagement/src/AppointmentReschedule.web')),
    path: "/AppointmentReschedule"
  },
  Confirmation: {
    component: LazyComponent(() => import('../../blocks/appointmentmanagement/src/Confirmation.web')),
    path: '/Confirmation'
  },
  ConfirmationService: {
    component: LazyComponent(() => import('../../blocks/appointmentmanagement/src/ConfirmationService.web')),
    path: '/ConfirmationService'
  },
  StaticPages: {
    component: LazyComponent(() => import('../../blocks/staticpages/src/StaticPages.web')),
    path: '/help-centre',
    exact: true
  },
  StaticPageDetail: {
    component: LazyComponent(() => import('../../blocks/staticpages/src/StaticPages.web')),
    path: '/help-centre/:id',
    exact: true
  },
  ServiceProviders: {
    component: LazyComponent(() => import('../../blocks/accountgroups/src/StaffListingPage.web')),
    path: '/ServiceProviders',
    exact: true
  },
  StaffSearch: {
    component: LazyComponent(() => import('../../blocks/accountgroups/src/StaffListingPage.web')),
    path: "/ServiceProviders/:searchQuery",
    exact: true
  },
  StaffDetails: {
    component: LazyComponent(() => import('../../blocks/accountgroups/src/StaffDetails.web')),
    path: '/StaffDetails/:id',
    exact: true
  },
  Advancesearch: {
    component: LazyComponent(() => import('../../blocks/accountgroups/src/Advancesearch.web')),
    path: '/Advancesearch/:searchQuery',
    exact: true
  },
  OrderStatus: {
    component: LazyComponent(() => import('../../blocks/appointmentmanagement/src/OrderStatus.web')),
    path: '/OrderStatus',
    exact: true
  },
  
  ResheduleConfirmStatus: {
    component: LazyComponent(() => import('../../blocks/appointmentmanagement/src/ResheduleConfirmStatus.web')),
    path: '/ResheduleConfirmStatus',
    exact: true
  },
  Tappaymentsintegration: {
    component: LazyComponent(() => import('../../blocks/tappaymentsintegration/src/Tappaymentsintegration.web')),
    path: "/payment_loading",
    exact: true
  },

  TapPayConfirmScreenWeb: {
    component: LazyComponent(() => import('../../blocks/tappaymentsintegration/src/TapPayConfirmScreen.web')),
    path: "/orderConfirm",
    exact: true
  },
};


// const firebaseAPI = firebase.initializeApp({
//   apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
//   authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
//   databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
//   projectId: 'rnmasterapp-c11e9',
//   storageBucket: 'rnmasterapp-c11e9.appspot.com',
//   messagingSenderId: '649592030497',
//   appId: '1:649592030497:web:7728bee3f2baef208daa60',
//   measurementId: 'G-FYBCF3Z2W3'
// });

const fallbackLoader = (
  <div style={{
    width: "100%",
    height: "100vh", // Use 100vh to cover the full viewport height
    position: "relative",
    display: "flex", // Use flexbox to center content
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    backgroundColor: "#fff" // Background color to match Skeleton
  }}>
    <Skeleton
      variant="rect"
      width="100%"
      height="100%"
      animation="wave"
    />
  </div>
);


class App extends Component {
  render() {
    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');

    const isAdminRoute = window.location.href.includes('admin'); 
    const storeName = localStorage.getItem("store_name")
    document.title = isAdminRoute ? 'Admin Console' : storeName;

    return (
      <View>
        <ThemeProvider theme={theme}>
          <Suspense fallback={fallbackLoader}>
            {WebRoutesGenerator({ routeMap, Appheader, Appfooter, location: this.props.location })}
          </Suspense>
          <WebsiteBuilderTheme/>
          <ModalContainer />
        </ThemeProvider>
      </View>
    );
  }
}

export default withRouter(App);
