// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RefObject } from "react";
export const configJSON = require("./config");
import { FilterType, OrderType, PaginationType } from "./types";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import StorageProvider from "../../../framework/src/StorageProvider";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { format } from 'date-fns';
// Customizable Area End

export interface Props {
  navigation: {
    navigate: (to: string, params?: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
    classes: Record<string, string>;
  };
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

// Customizable Area Start
export interface PostOrderStatusFailureResponse {
  errors: Array<string>
}

export interface StaffData {
  data: Array<staffDataArray>;
}

export interface staffDataArray {
  id: string;
  type: string;
  isChecked: boolean;
  attributes: {
    email: string;
    full_name: string;
    phone_number: null;
    description: string;
    designation: string;
  },
}

export interface OrderSummary {
  id: string;
  type: string;
  attributes: {
    order_number: string;
    appointment_date: string;
    order_date: string;
    status: string;
    total: number;
    catalogue_price: number;
    service: {
      title: string;
      price: number;
      duration: number;
      discount_price: number;
      discount_option: boolean;
    };
    service_images: {
      id: number;
      url: string;
    };
    service_provider: {
      id: number;
      full_name: string;
      email: string;
      designation: string;
      image: string;
      phone_number: string;
    };
    payment_mode: string;
    customer: {
      id: number;
      full_phone_number: string;
      email: string;
      full_name: string;
      created_at: string;
      updated_at: string;
      appointment_id: number;
      comment: string | null;
    };
    billing_address: {
      id: number;
      country: string;
      city: string;
      zip_code: string;
      state: string;
      address_line_1: string;
      address_line_2: string;
      flat_number: string | null;
      created_at: string;
      updated_at: string;
      appointment_id: number;
    };
    time_zone_short: string;
  };
}




interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
}
interface ServiceProviderAttributes {
  id: number;
  full_name: string;
  designation: string;
  slug: string;
  image: string;
}
interface ServiceProvider {
  id: string;
  type: string;
  attributes: ServiceProviderAttributes;
}
interface Customer {
  id: number;
  full_phone_number: string;
  email: string;
  full_name: string;
  created_at: string; 
  updated_at: string; 
  appointment_id: number;
  comment: string;
}
export interface ServiceProviders {
  id: string;
  full_name: string;
  image: string;
}

interface Service {
  id: number;
  title: string;
  price: number;
  duration: number;
  discount_price: number;
  discount_option: boolean;
}
interface BillingAddress {
  id: number;
  country: string;
  city: string;
  zip_code: string;
  state: string;
  address_line_1: string;
  address_line_2: string;
  flat_number: string;
  created_at: string; 
  updated_at: string; 
  appointment_id: number;
}
interface CancellationPolicy {
  is_cancellation_policy: boolean;
  no_of_days_cancel: number;
  cancel_text: string;
}

interface ReschedulePolicy {
  is_reschedule_policy: boolean;
  no_of_days_reschedule: number;
  reschedule_text: string;
}

interface RefundPolicy {
  is_refund_policy: boolean;
  refund_text: string;
}
interface Country {
  id: number;
  name: string;
  code: string;
  phone_code: string;
}

interface Currency {
  id: number;
  name: string;
  symbol: string;
}
interface TimeSlot {
  id: number;
  slot_start_time: string;
  slot_end_time: string;
  is_available: boolean;
}

interface OrderAttributes {
  service_provider: any;
  service: any;
  status: string;
  total: number;
  order_number: string;
  order_date: string; 
  appointment_date: any; 
  customer: Customer;
  country: Country;
  currency: Currency;
  duration: string;
}

interface OrderOtherAttributes {
  status: string;
  total: number;
  booking_type: string;
  order_number: string;
  order_date: string;
  appointment_date: string;
  customer: Customer;
  service_provider: ServiceProviders;
  service: Service;
  country: Country;
  currency: Currency;
  time_slot: TimeSlot;
}


interface Order {
  id: string;
  type: string;
  attributes: OrderAttributes;
}
interface Orders {
  id: string;
  type: string;
  attributes: OrderOtherAttributes;
}

interface AppointmentSummaryRes {
  order_number: string,
  status: string,
  total: number,
  service: {
    id: number,
    title: string,
    price: number,
    discount_price: number,
    discount_option: boolean,
    duration: number
  },
  service_images: {
    id: number,
    url: string,
    webp_url: string,
    small_url: string,
    medium_url: string
  },
  payment_mode: string,
  currency: {
    id: number,
    name: string,
    symbol: string
  },
  updated_by_id: number,
  catalogue_price: number,
  booking_type: string,
  timezone: string,
  time_zone_short: string,
  order_date: string,
  appointment_date: string,
  customer: {
    id: number,
    full_phone_number: string,
    email: string,
    full_name: string,
    created_at: string,
    updated_at: string,
    appointment_id: number,
    comment: string
  },
  country: {
    id: number,
    name: string,
    code: string,
    phone_code: string
  },
  service_provider: {
    id: number
    full_name: string
  },
  billing_address: {
    country: string;
    city: string,
    state: string,
    address_line_1: string,
    address_line_2: string,
    zip_code: string
  },
  cancellation_policy: {
    is_cancellation_policy: boolean,
    no_of_days_cancel: number,
    cancel_text: string
  },
  reschedule_policy: {
    is_reschedule_policy: boolean,
    no_of_days_reschedule: number,
    reschedule_text: string
  },
  refund_policy: {
    is_refund_policy: boolean,
    refund_text: string
  },
  time_slot: {
    id: {
      service: {
        id: number
      }
    },
    slot_start_time: string,
    slot_end_time: string,
    end_start_time: string
  }
}

interface ServiceProviderList {
  data: Array<ServiceProviders>
}
// Customizable Area End

interface S {
  // Customizable Area Start
  token: string;
  selectedTime: string;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  orderList_Data: OrderType[];
  isDialogOpen: boolean;
  updateAppointmentSuccess:boolean;
  searchQuery: string;
  eventCatalogueId:any,
  serviceStaffId:any,
  adminSearch?: string;
  selectedValue: string | null;
  orderSummary: OrderSummary | null;
  isLoading: boolean;
  isOrderBlank: boolean;
  isFormChanged: boolean;
  dateError: string | null;
  totalError: string | null;
  filters?: FilterType;
  isCsvDownloading: boolean;
  pagination: PaginationType;
  isOpen: boolean;
  isTotalOpen: boolean;
  isDateOpen: boolean;
  catalogueId:any;
  deletionAppointmentError: any;
  isServiceProviderOpen: boolean;
  buildCardID: string;
  onlineBooking: boolean | null;
  selectedDate: Date | null;
  selectNewDate:any;
  isOpenFilter: boolean;
  isOpenServices: boolean;
  highlightedDate: Date | null;
  billingAddressCountry: any;
  billingAddressCity: any;
  billingAddressState: any
  billingAddressAddLine1: any
  billingAddressAddLine2: any
  billingAddressZipCode: any;
  servicesList: ServiceProvider[];
  serviceSlotBooking: Order[];
  staffListData: Array<staffDataArray>;
  staffId:any;
  serviceTimeSlotData: Array<TimeSlot>;
  staffTimeSlotData: Array<TimeSlot>;
  serviceId:any;
  showEditForm: boolean;
  appointmentSummaryData: AppointmentSummaryRes;
  appointmentServiceId:number;
  serviceProviderData: string;
  isModalOpened: boolean;
  currentView: string;
  showServiceProvider: boolean;
  selectedFieldDate: any;
  selectedFieldFormatedTime: any;
  staffName:string;
  showFiltersData:boolean;
  serviceProviderId: any;
  newServiceProviderData: string;
  appointmentCurrency: any;
  selectedTimeDate: any
  showDeleteDialog: boolean;
  selectedId: any;
  timeSlotDate: any;
  filterData:Orders[];
  editAppointmentStaff: Array<ServiceProviders>;
  cancelAppointmentStatus: string;
  isSlotPresent: string;
  // Customizable Area End
}

interface SS { }
export default class CalendarViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  orderCategoryAddApiCallId: string = "";
  orderCategoryGetApiCallId: string = "";
  orderCalendarGetApiCallId: string = "";
  updateAppointmentApiCallId: string = "";
  deleteApiCallId: string = "";
  orderServicesGetApiCallId: string = "";
  serviceProviderGetApiCallId: string = "";
  serviceGetApiCallId: string = "";
  staffEditAppointmentGetApiCallId:string = "";
  serviceStaffFilterApiCallId:string = "";
  appointmentSummaryGetApiCallId: string = "";
  searchCategoryGetApiCallId: string = "";
  orderStatusGetApiCallId: string = "";
  appointmentGetApiCallId: string = "";
  getStaffListingApiCall: string = "";
  // Customizable Area End
  debounceTimer: NodeJS.Timeout | null = null;
  queryRef: React.RefObject<HTMLInputElement>;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.queryRef = React.createRef();

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];

    this.submitButtonRef = React.createRef();
    this.resetButtonRef = React.createRef();

    this.state = {
      showDeleteDialog: false,
      selectedTime: "10:00 AM-11:00 PM",
      isModalOpened: false,
      selectedDate: new Date(),
      selectNewDate:"",
      isOpenFilter: false,
      serviceSlotBooking: [],
      isOpenServices: false,
      token: "",
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      orderList_Data: [],
      isDialogOpen: false,
      searchQuery: '',
      orderSummary: null,
      selectedValue: null,
      isLoading: false,
      isOrderBlank: false,
      isFormChanged: false,
      totalError: null,
      dateError: null,
      isCsvDownloading: false,
      isOpen: true,
      isTotalOpen: true,
      isDateOpen: true,
      updateAppointmentSuccess : false,
      pagination: {} as PaginationType,
      deletionAppointmentError: "",
      isServiceProviderOpen: true,
      buildCardID: "",
      onlineBooking: null,
      highlightedDate: new Date(),
      servicesList: [],
      staffListData: [],
      staffId:[],
      serviceId:[],
      serviceTimeSlotData: [],
      staffTimeSlotData: [],
      showEditForm: false,
      eventCatalogueId:"",
      serviceStaffId:"",
      appointmentSummaryData: {} as AppointmentSummaryRes,
      appointmentServiceId:0,
      serviceProviderData: "",
      currentView: "daily",
      showServiceProvider: false,
      selectedFieldDate: "",
      selectedFieldFormatedTime: "",
      billingAddressCountry: "",
      billingAddressCity: "",
      billingAddressState: "",
      billingAddressAddLine1: "",
      billingAddressAddLine2: "",
      billingAddressZipCode: "",
      staffName:"",
      showFiltersData:false,
      catalogueId:"",
      serviceProviderId: "",
      newServiceProviderData: "",
      appointmentCurrency: "",
      selectedTimeDate: "",
      selectedId: "",
      timeSlotDate: "",
      filterData:[],
      editAppointmentStaff:[],
      cancelAppointmentStatus: "",
      isSlotPresent: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start  
  downloadCSVMessageId: string = "";
  async componentDidMount() {
    super.componentDidMount();
    const buildCardID = await StorageProvider.get("buildCardID");
    this.setState({
      buildCardID: buildCardID
    });
    this.getServicesList();
    this.getToken();
    this.getCalendarListOrder();
    this.getStaffListing();
    if (location.pathname.includes("OrderManagementDetails")) {
      this.callAPI();
    } else {
      this.getListOrder();
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.handleDownloadCSV(message);
      if (responseJson.data) {
        if(apiRequestCallId === this.updateAppointmentApiCallId){
          this.handleUpdateResponse()
        }
        if (apiRequestCallId === this.orderCategoryGetApiCallId) {
          this.handleApiResponse(message, true);
        }
        else if(apiRequestCallId === this.deleteApiCallId){
          this.setState({ cancelAppointmentStatus: responseJson.data.attributes.status })
          this.handleUpdateResponse();
        }
        else if (apiRequestCallId === this.orderCalendarGetApiCallId) {
          this.setState({ serviceSlotBooking: responseJson.data })
        }
        else if (apiRequestCallId === this.orderServicesGetApiCallId) {
          this.setState({ servicesList: responseJson.data })
        }
        else if (apiRequestCallId === this.serviceGetApiCallId) {
          this.setState({ serviceTimeSlotData: responseJson.data.attributes.time_slots, isSlotPresent: "" })
        }
        else if (apiRequestCallId === this.serviceProviderGetApiCallId) {
          this.setState({ staffTimeSlotData: responseJson.data.attributes.time_slots, isSlotPresent: "" })
        }
        else if(apiRequestCallId === this.staffEditAppointmentGetApiCallId){
          this.setState({editAppointmentStaff  : responseJson.data})
        }
        else if (apiRequestCallId === this.serviceStaffFilterApiCallId) {
          this.setState({ filterData: responseJson.data})
        }
        else if (apiRequestCallId === this.appointmentSummaryGetApiCallId) {
          this.setState({ appointmentSummaryData: responseJson?.data?.attributes })
          this.getStaffEditAppointment(this.state.appointmentSummaryData.service.id);

          this.setState({
            selectedFieldDate: this.state.appointmentSummaryData.appointment_date,
            selectedFieldFormatedTime: responseJson?.data?.attributes.appointment_date, 
            appointmentServiceId:this.state.appointmentSummaryData.service.id})
          this.setState({ serviceProviderData: this.state.appointmentSummaryData?.service_provider?.full_name })
          this.setState({ 
            billingAddressCountry: this.state?.appointmentSummaryData?.billing_address?.country,
            billingAddressCity: this.state?.appointmentSummaryData?.billing_address?.city,
            billingAddressState: this.state?.appointmentSummaryData?.billing_address?.state,
            billingAddressAddLine1: this.state?.appointmentSummaryData?.billing_address?.address_line_1,
            billingAddressAddLine2: this.state?.appointmentSummaryData?.billing_address?.address_line_2,
            billingAddressZipCode: this.state?.appointmentSummaryData?.billing_address?.zip_code,
            catalogueId :this.state?.appointmentSummaryData?.service?.id
           })
        }

        else if (apiRequestCallId === this.orderCategoryAddApiCallId) {
          this.handleApiResponse(message, false);
        } else if (apiRequestCallId === this.searchCategoryGetApiCallId) {
          this.handleApiResponse(message, false);
        } else if (apiRequestCallId === this.orderStatusGetApiCallId) {
          this.updateState(message);
          this.goBack();
        } else if (apiRequestCallId === this.appointmentGetApiCallId) {
          this.updateState(message);
        } else if (apiRequestCallId === this.getStaffListingApiCall) {
          this.getStaffListingSuccess(responseJson)
        }
      } 
      else if (responseJson?.errors) {
        if (apiRequestCallId === this.orderStatusGetApiCallId) {
          this.handlePostOrderStatusFailureResponse(responseJson);
          this.setState({ isLoading: false });
          mixpanel.track(`${this.state.buildCardID}_${configJSON.errorOrderStatus}`, { buildCardID: this.state.buildCardID, error: responseJson?.errors });
        } if (apiRequestCallId === this.orderCategoryGetApiCallId) {
          mixpanel.track(`${this.state.buildCardID}_${configJSON.errorOrderCategorList}`, { buildCardID: this.state.buildCardID, error: responseJson?.errors });
        } else if (apiRequestCallId === this.orderCategoryAddApiCallId) {
          mixpanel.track(`${this.state.buildCardID}_${configJSON.errorAddOrderCategory}`, { buildCardID: this.state.buildCardID, error: responseJson?.errors });
        } else if (apiRequestCallId === this.searchCategoryGetApiCallId) {
          mixpanel.track(`${this.state.buildCardID}_${configJSON.errorSearchOrderCategory}`, { buildCardID: this.state.buildCardID, error: responseJson?.errors });
        } else if (apiRequestCallId === this.appointmentGetApiCallId) {
          mixpanel.track(`${this.state.buildCardID}_${configJSON.errorGetAppointment}`, { buildCardID: this.state.buildCardID, error: responseJson?.errors });
        }
        else if(apiRequestCallId === this.deleteApiCallId){
          this.handlePostOrderStatusFailureResponse(responseJson);
          this.setState({ isLoading: false });
        }
        if(apiRequestCallId === this.updateAppointmentApiCallId){
          this.setState({ updateAppointmentSuccess: false });
        }
        else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
          this.setState({ isLoading: false });
        }
      } 
      else if (responseJson?.message) {
        if (apiRequestCallId === this.serviceGetApiCallId) {
          this.setState({ isSlotPresent: responseJson?.message, serviceTimeSlotData: [] })
        }
        else if (apiRequestCallId === this.serviceProviderGetApiCallId) {
          this.setState({ isSlotPresent: responseJson?.message, staffTimeSlotData: []})
        }
      }
    }
  }

  callApi = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const token = await getStorageData("admintoken")
    const header = {
      "Content-Type": "application/json",
      token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  handleEventClick = async (event: React.FormEvent<HTMLInputElement> | any) => {
    await setStorageData("orderID", event.id.toString());
    const eventId = event.id;
    const timeSlotDates = event?.timeSlotDate;
    const eventCatalogue = event.catalogueId;
    const serviceProvider = event.serviceProviderId;
    const staffName = event.staffName;
    
    this.setState({ timeSlotDate: timeSlotDates,
      eventCatalogueId: eventCatalogue,
      serviceStaffId: serviceProvider,
      serviceProviderId: serviceProvider,
      serviceProviderData: staffName,
    }, () => {
      this.state.serviceProviderData ? this.getServiceProviderTime() : this.getServiceTime();
    });
    this.setState({ selectedId: eventId })
    this.setState({ showEditForm: true })
    this.getAppointmentSummary(eventId);
  }
  formatTime24to12(time24:any) {
    let [hours, minutes] = time24.split(':').map(Number);
    let period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; 
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
  }

  navigateToCreateBooking = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "CreateBooking");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  }

  navigateToOrderDetails = async () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "OrderMangementList/OrderManagementDetails");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  }


  addOrdinalSuffix = (day:any) => {
    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
      case 1: return `${day}st`;
      case 2: return `${day}nd`;
      case 3: return `${day}rd`;
      default: return `${day}th`;
    }
  };
  
  formatDFateWithOrdinal = (date:any) => {
    const parsedDate = date instanceof Date ? date : new Date(date);
    
    if (isNaN(parsedDate.getTime())) return '';
    
    const day = format(parsedDate, 'd'); 
    const month = format(parsedDate, 'MMMM');
    const year = format(parsedDate, 'yyyy'); 
    const weekday = format(parsedDate, 'EEE');
    
    return `${weekday}, ${this.addOrdinalSuffix(day)} ${month} ${year}`;
  };
  formatTimeSlotDate(dateString: any) {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  handleCheckboxClick = (id: string) => {
    this.setState({ showFiltersData: true });
    
    this.setState((prevState) => {
      const { staffId } = prevState;
      const updatedStaffId = staffId.includes(id)
        ? staffId.filter((staffId: string) => staffId !== id)
        : [...staffId, id];
  
      return { staffId: updatedStaffId };
    }, () => {
      if (this.state.staffId.length === 0) {
        this.setState({ showFiltersData: false });
      } else {
        this.getServiveStaffFilterApi();
      }
    });
  }
  handleServiceCheckboxClick = (id: string) => {
    this.setState({ showFiltersData: true });
  
    this.setState((prevState) => {
      const { serviceId } = prevState;
      const updatedServiceId = serviceId.includes(id)
        ? serviceId.filter((serviceId: string) => serviceId !== id)
        : [...serviceId, id];
  
      return { serviceId: updatedServiceId };
    }, () => {
      if (this.state.serviceId.length === 0) {
        this.setState({ showFiltersData: false });
      } else {
        this.getServiveStaffFilterApi();
      }
    });
  }

  handleStaffChange = (event:React.FormEvent<HTMLInputElement> | any) => {
        this.setState({ serviceProviderId: event.target.value }, () => {
      this.getServiceProviderTime();
    });
  };

  handleApiResponse(message: Message, isOrderBlank: boolean) {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({ orderList_Data: responseJson.data});
    this.setState({ isLoading: false });
  }

  handlePostOrderStatusFailureResponse = (failureResponse: PostOrderStatusFailureResponse) => {
    this.setState({
      deletionAppointmentError: failureResponse.errors
    }, () => {
      setTimeout(() => {
        this.setState({ deletionAppointmentError: "" });
      }, 4000);
    })
  }
  handleUpdateResponse = () => {
   this.setState({ updateAppointmentSuccess: true }, () => {
        setTimeout(() => {
          this.setState({ updateAppointmentSuccess: false });
        }, 4000);
      })
   
  }
 
  handleDateChange = async (date: any) => {
    this.setState({ showFiltersData: true });
      this.setState({
        selectedDate: new Date(String(date)),
        selectNewDate : new Date(String(date)),
        highlightedDate: new Date(String(date)),
        isLoading: true
      },() => this.getServiveStaffFilterApi())
    
  };
  
  handleFieldDateChange = (date: Date | null) => {
    this.setState({ selectedFieldDate: date, timeSlotDate: date }, () => {
      this.state.serviceProviderData ? this.getServiceProviderTime() : this.getServiceTime();
    })
  }

  handleTimeChange = (event: React.FormEvent<HTMLInputElement> | any) => {
    this.setState({ selectedTimeDate: event.target.value })
  };
  formatTimeRange = (time:any) => {
    const date = new Date(time);
    let startHours = date.getHours();
    let startMinutes = date.getMinutes();
    
    let endHours = startHours + 1;
    let endMinutes = startMinutes;
    
    const formatHour = (hour:any) => {
      let ampm = hour >= 12 ? 'PM' : 'AM';
      hour = hour % 12;
      hour = hour ? hour : 12; 
      return `${hour < 10 ? '0' + hour : hour}:${endMinutes < 10 ? '0' + endMinutes : endMinutes} ${ampm}`;
    };

    const startPeriod = startHours >= 12 ? 'PM' : 'AM';
    const endPeriod = endHours >= 12 ? 'PM' : 'AM';

    if (endHours === 12 && startHours < 12) {
      endHours = 12; 
    } else if (endHours > 12) {
      endHours = endHours - 12; 
    }

    return `${formatHour(startHours)} - ${formatHour(endHours)}`;
  };

  getStaffListing = async () => {
    this.getStaffListingApiCall = await this.callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.listOfOrdersMethod,
      endPoint: configJSON.getStaffList
    })
  };

  getStaffListingSuccess = (responseJson: StaffData) => {
    let responseData = responseJson.data;

    responseData.forEach((item: staffDataArray) => {
      item["isChecked"] = false
    });
    this.setState({ staffListData: responseData })
  };

  filterOrder = async (values: any) => {
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderListFilter}`, { buildCardID: this.state.buildCardID });
    this.setState({ isLoading: true, filters: values, adminSearch: "" })
    const header = {};
    let apiEndPoint = `${configJSON.orderlistfilterEndPoint}?[start_date]=${values}?[end_date]=${values}`
    this.orderCategoryAddApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      contentType: configJSON.apiContentType,
    });
  };


  serach_orders = async (searchtxt: string) => {
    const header = {};
    const apiEndPoint = configJSON.ordercategoryApiEndPoint + `?search_query=${searchtxt}`;
    this.searchCategoryGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      contentType: configJSON.apiContentType
    });

  }



  serach_orders_list = (queryRef: RefObject<HTMLInputElement>) => {

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      const query = (queryRef.current && queryRef.current.value) || "";

      this.adminSearch(query);
    }, 800);


  }

  adminSearch = async (search: string, page = 1) => {
    this.setState({ isLoading: true, filters: undefined, adminSearch: search })
    if (search.toString().length > 0) {
      mixpanel.track(`${this.state.buildCardID}_${configJSON.orderListSearch}`, { buildCardID: this.state.buildCardID });
      const header = {};
      const apiEndPoint = configJSON.ordercategoryApiEndPoint + `?search_query=${search}&page=${page}`;
      this.searchCategoryGetApiCallId = await this.callApi({
        method: configJSON.listOfOrdersMethod,
        endPoint: apiEndPoint,
        contentType: configJSON.apiContentType
      });
    } else if (search.toString().length == 0) {
      this.getListOrder()
    }
    clearTimeout(this.debounceTimer!);
  }
  handleDeleteDialogClose = () => {
    this.setState({
      showDeleteDialog: false,

    });
  };

  handleConfirmDelete = async (id: string) => {
    this.setState({
      showDeleteDialog: false,
      showEditForm: !this.state.showEditForm
    });
    this.deleteEditAppointmentData(id);
  }


  getListOrder = async (page = 1) => {
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    this.setState({ isLoading: true, filters: undefined, adminSearch: "" })
    const header = {};
    this.orderCategoryGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: configJSON.orderlistEndPoint + "?page=" + page,
      contentType: configJSON.apiContentType
    });
  };

  getCalendarListOrder = async () => {
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    this.setState({ isLoading: true, filters: undefined, adminSearch: "" })
    const header = {};
    this.orderCalendarGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: "bx_block_appointment_management/appointments/order_list?show_all=true",
      contentType: configJSON.apiContentType
    });
  };
  getServicesList = async () => {
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    this.setState({ isLoading: true, filters: undefined, adminSearch: "" })
    const header = {};
    this.orderServicesGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: configJSON.servicesListEndPoint,
      contentType: configJSON.apiContentType
    });
  };

  formatDateAppointment(dateString: any) {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }
  getServiveStaffFilterApi = async () => {
    const formatDate = (date:any) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const day = d.getDate();
    
      return `${year}-${month}-${day}`;
    };

    const givenDate = moment(this.state.selectNewDate);
    const startOfWeek = givenDate.clone().startOf("week").format("YYYY-MM-DD");
    const endOfWeek = givenDate.clone().endOf("week").format("YYYY-MM-DD");

    const formattedDate = this.state.selectNewDate && formatDate(this.state.selectNewDate)
    const {staffId,serviceId} = this.state
    const staffIdData = staffId.length > 0 ? `&[service_provider_id]=${staffId.join(',')}` : "";
    const serviceIdData = serviceId.length > 0 ? `&[service_id]=${serviceId.join(',')}` : "";
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    const header = {};
    const manageDate =  formattedDate ? `[start_date]=${startOfWeek}&[end_date]=${endOfWeek}` : ""
    this.serviceStaffFilterApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint :`${configJSON.orderlistfilterEndPoint}?${manageDate}${staffIdData}${serviceIdData}&show_all=true`,
      contentType: configJSON.apiContentType
    });
  };
  getServiceTime = async () => {
    const {timeSlotDate,eventCatalogueId} = this.state
    const formattedDate = this.formatTimeSlotDate(timeSlotDate)
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    this.serviceGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: `bx_block_appointment_management/availabilities?catalogue_id=${eventCatalogueId}&availability_date=${formattedDate}`,
      contentType: configJSON.apiContentType
    });
  };

  getServiceProviderTime = async () => {
    const {serviceProviderId, timeSlotDate, eventCatalogueId} = this.state;
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    const formattedDate = this.formatTimeSlotDate(timeSlotDate)
    this.serviceProviderGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: `bx_block_account_groups/customer_service_providers/service_provider_availabilites?catalogue_id=${eventCatalogueId}&availability_date=${formattedDate}&service_provider_id=${serviceProviderId}`,
      contentType: configJSON.apiContentType
    });
  };

  getStaffEditAppointment = async (id:number) => {
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    this.staffEditAppointmentGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: `bx_block_catalogue/catalogues/service_providers?id=${id}`,
      contentType: configJSON.apiContentType
    });
  };
  updateEditAppointment = async (id: string) => {
    this.setState({ isFormChanged: false });
    const formattedDate = this.formatDateAppointment(this.state.selectedFieldDate);
    const body = {
      "appointment": {
        "time_slot_id":this.state.selectedTimeDate,
        "catalogue_id":this.state.appointmentSummaryData.time_slot.id?.service?.id,
        "currency": this.state.appointmentSummaryData.currency.name,
        "service_provider_id": (this.state.serviceProviderId ? this.state.serviceProviderId : ""),
        "appointment_date": formattedDate,
        "personal_detail_attributes":{
          "full_name":this.state.appointmentSummaryData.customer.full_name,
          "full_phone_number":this.state.appointmentSummaryData.customer.full_phone_number,
          "email":this.state.appointmentSummaryData.customer.email
      },
        "billing_address_attributes": {
          "country": this.state?.billingAddressCountry,
          "city": this.state?.billingAddressCity,
          "state": this.state?.billingAddressState,
          "address_line_1": this.state?.billingAddressAddLine1,
          "address_line_2": this.state?.billingAddressAddLine2,
          "zip_code": this.state?.billingAddressZipCode
        }
      }
    }


    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderDetailUpdate}`, { buildCardID: this.state.buildCardID });

    this.updateAppointmentApiCallId = await this.callApi({
      method: "PUT",
      endPoint: `${configJSON.updateAPICalendarEndPoint}?id=${id}`,
      body
    });
  };
  deleteEditAppointmentData = async (id: string | any) => {
    this.getCalendarListOrder();
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    const header = {};
    this.deleteApiCallId = await this.callApi({
      method: "PUT",
      endPoint: `bx_block_appointment_management/appointments/appointment_cancel_customer?id=${id}`,
      contentType: configJSON.apiContentType
    });
  }

  getAppointmentSummary = async (id: number) => {
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    this.setState({ isLoading: true, filters: undefined, adminSearch: "" })
    const header = {};
    this.appointmentSummaryGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: `bx_block_appointment_management/appointments/appointment_summary?id=${id}`,
      contentType: configJSON.apiContentType
    });
  };

  getList = async (itemId: string) => {
    await setStorageData("orderID", itemId.toString());
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "OrderMangementList/OrderManagementDetails");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  }

  handleNavigateToOrderList = async () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "OrderMangementList");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);

  }
  handleNavigateToCalendarList = async () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "CalendarViewList");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);

  }

  commonSettingNavigation = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "CommonSettingsAdmin");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  }


  orderStatus = async (itemId: string | null, status: { selectedValue: string | null }) => {
    this.setState({ isLoading: true })
    const header = {};
    const body = new FormData();
    body.append("[appointment][status]", status.selectedValue + "");
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderDetailUpdate}`, { buildCardID: this.state.buildCardID });

    this.orderStatusGetApiCallId = await this.callApi({
      method: configJSON.createOrderMethod,
      endPoint: configJSON.orderstatusApiEndPoint + `?id=${itemId}`,
      body
    });
  };



  appointment_summary = async (itemId: string | null) => {
    mixpanel.track(`${this.state.buildCardID}_${configJSON.orderDetailEnter}`, { itemId, buildCardID: this.state.buildCardID });
    const header = {};
    const apiEndPoint = configJSON.routeAppointmentSummary + `?id=${itemId}`;
    this.appointmentGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      contentType: configJSON.apiContentType
    });

  }

  handleDownloadCSV = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (this.downloadCSVMessageId === apiRequestCallId) {
      this.setState({ isCsvDownloading: false });
      if (responseJson.csv_data) {
        const csvRows = responseJson.csv_data;
        let csvContent = "data:text/csv;charset=utf-8,";

        csvRows.forEach(function (rowArray: string[]) {
          let rowData = rowArray.join(",");
          csvContent += rowData + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", configJSON.textCSVFileName);
        document.body.appendChild(link);
        link.click();
      }
    }
  }

  download = async () => {
    const header = {};
    const apiEndPoint = configJSON.routeDownloadCSV;
    this.downloadCSVMessageId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      contentType: configJSON.apiContentType
    });
    this.setState({ isCsvDownloading: true });
  }


  handleStatusChange = (newValue: string | null) => {
    this.setState({ selectedValue: newValue });
  }

  updateState = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const response = responseJson.data;
    this.setState({ isLoading: false });
    this.setState({ orderSummary: response });
    this.setState({ selectedValue: response.attributes.status });
  }

  resetFilter = () => {
    this.getListOrder();
    this.setState({ isDialogOpen: false, dateError: null, totalError: null, filters: undefined, adminSearch: "" });
  }


  submitButtonRef: RefObject<HTMLButtonElement>;
  resetButtonRef: RefObject<HTMLButtonElement>;

  callAPI = async () => {
    const itemId = await getStorageData("orderID")
    this.appointment_summary(itemId)
  }

  formatDate = (inputDate: string): string => {
    const parsedDaties = new Date(inputDate);

    if (isNaN(parsedDaties.getTime())) {
      return "Invalid Date";
    }

    const optiones: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    };

    const formatter = new Intl.DateTimeFormat('en-US', optiones);
    let formattedDate = formatter.format(parsedDaties);

    formattedDate = formattedDate.replace(/, (\d{4})/, ' $1,');
    formattedDate = formattedDate.replace(" at", "");
    formattedDate = formattedDate.replace(/(\d{1,2}) /, '$1 ');

    return formattedDate;
  };



  handleChange = (event: string, newValue: string | null) => {
    this.setState({ selectedValue: newValue });
  };

  handleSubmitDetail = async (values: { selectedValue: string | null }) => {
    // const itemId = await getStorageData("orderID");
    // this.orderStatus(itemId, values)
  };

  saveChanges = () => {
    if (this.submitButtonRef.current) {
      this.submitButtonRef.current.click();
      this.setState({ isFormChanged: false })

    }
  };

  discardChanges = () => {
    this.setState({ isFormChanged: false });
  };
  handleFieldFocus = () => {
    this.setState({ isFormChanged: true });
  }


  handleDialogOpen = () => {
    this.setState({ isDialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false, dateError: null, totalError: null });
  };


  handleSubmitList = (values: FilterType) => {
    let isError = false;
    const { from_date, to_date, integerField1, integerField2 } = values;
    if ((from_date && !to_date) || (to_date && !from_date)) {
      isError = true;
      this.setState({ dateError: configJSON.textSelectDateValidation });
    } else {
      this.setState({ dateError: null })
    }
    if ((integerField1 && !integerField2) || (integerField2 && !integerField1)) {
      isError = true;
      this.setState({ totalError: configJSON.textSelectAmountValidation })
    } else {
      this.setState({ totalError: null })
    }
    if (isError) {
      return;
    }
    this.filterOrder(values);
    this.handleDialogClose();

  };


  fetchSearchResults = () => {
    const { searchQuery } = this.state;

    if (searchQuery !== null && searchQuery !== '') {
      if (searchQuery.length > 0) {
        this.serach_orders(searchQuery)
      }

    }
  };

  handlePageChange = (page: number) => {
    if (this.state.filters) {
      // this.filterOrder(this.state.filters, page);
    } else if (this.state.adminSearch) {
      this.adminSearch(this.state.adminSearch, page)
    } else {
      this.getListOrder(page);
    }
  }

  handleOrderDate = (order_date: string) => {
    return moment.utc(order_date).format("MMMM Do YYYY, LT");
  };

  handleAppointmentDate = (appointment_date: string, time_zone_short: string) => {
    return moment.utc(appointment_date).format("dddd, Do MMMM YYYY | h:mm A ([" + time_zone_short + "])");
  };

  handleOrder_Date = (order: string) => {
    return moment.utc(order).format(configJSON.dateFormat);
  };

  handleAppointmen_Date = (appointment_date: string, time_zone_short: string) => {
    return moment.utc(appointment_date).format(time_zone_short);
  };

  handleOrderStatus = (newValue: string | string[] | null) => {
    this.setState({ isFormChanged: true });
    if (newValue === null) {
      this.handleChange("selectedValue", "");
    } else if (Array.isArray(newValue)) {
      this.handleChange("selectedValue", newValue[0]);
    } else {
      this.handleChange('selectedValue', newValue);
    }
  };

  goBack = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "OrderMangementList");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  setIsTotalOpen = () => {
    this.setState({ isTotalOpen: !this.state.isTotalOpen });
  };

  setIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  setIsDateOpen = () => {
    this.setState({ isDateOpen: true });
  };

  setIsDateClose = () => {
    this.setState({ isDateOpen: false });
  };

  setIsServiceProviderClose = () => {
    this.setState({ isServiceProviderOpen: !this.state.isServiceProviderOpen });
  };

  // Customizable Area End
}
